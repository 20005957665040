<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4>
            الأماكن
            <button
              class="btn btn-sm btn-relief-primary"
              @click="$router.push('/places/add')"
            >
              <i class="fa fa-plus"></i> إضافة مكان
            </button>
          </h4>
        </div>
        <div class="card-body">
          <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered custom-table">
              <thead>
                <th>الاسم</th>
                <th>سعر التوصيل</th>
                <th>متاح التركيب</th>
                <th>سعر التركيب</th>
                <th>خيارات</th>
              </thead>
              <tbody>
                <tr v-for="place in places" :key="place._id">
                  <td>
                    {{ place.title }}
                  </td>
                  <td>
                    {{ place.delivery_price }}
                  </td>
                  <td>
                    {{ place.install ? "نعم" : "لا" }}
                  </td>
                  <td>
                    {{ place.install_price }}
                  </td>
                  <td>
                    <button
                      class="btn btn-sm btn-success"
                      @click="$router.push('/places/edit/' + place._id)"
                    >
                      <i class="fa fa-edit"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-outline-danger"
                      style="margin-right: 3px"
                      @click="deletePlace(place._id)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      places: [],
    };
  },
  created() {
    var g = this;
    $.post(api + "/console/places/list", {
      jwt: user.jwt,
    }).then(function (r) {
      g.places = r.response;
    });
  },
  methods: {
    deletePlace(id) {
      var g = this;
      if (confirm("متأكد من الحذف؟")) {
        $.post(api + "/console/places/delete", {
          jwt: user.jwt,
          id: id,
        }).then(function () {
          $.post(api + "/console/places/list", {
            jwt: user.jwt,
          }).then(function (r) {
            g.places = r.response;
          });
        });
      }
    },
  },
};
</script>

<style>
</style>